import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueResource from "vue-resource";
import "flexboxgrid";
import VueNativeSock from "vue-native-websocket";
import VueSocketIO from "vue-socket.io";
import VueLazyload from "vue-lazyload";
import VueElementLoading from 'vue-element-loading';
import * as io from "socket.io-client";
import i18n from "./i18n";
import Vuelidate from 'vuelidate'
import FlashMessage from '@smartweb/vue-flash-message';

Vue.use(FlashMessage);

Vue.use(Vuelidate)

Vue.component('VueElementLoading', VueElementLoading);

Vue.config.productionTip = false;

Vue.use(VueLazyload);

Vue.use(VueResource);

Vue.use(VueNativeSock, "wss://api.act-financial.pro:8443/", {
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

export const SocketInstance = io("wss://api.act-financial.pro:3002/", {
  path: "/socket.io",
  transports: ["websocket"]
});

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketInstance,
    vuex: {
      store,
      actionPrefix: "SOCKET_IO_",
      mutationPrefix: "SOCKET_IO_"
    }
  })
);

Vue.http.interceptors.push((request, next) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.set("Authorization", "Bearer " + token);
  }
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
