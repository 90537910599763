<template>
  <div id="public">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: "HomeLayout",
  created() {
    // allow only login and registration page from public site part
    if (this.$route.name === "Login"  || this.$route.name === "Registration" || this.$route.name === "EmailConfirmation")
    {

    }
    else
    {
      window.location = "https://act-financial.pro";
    }
  }
};
</script>
